import { MutationTree, Mutation } from 'vuex';
import { IState } from './state';
import { ActionPayload, setFormMessage, setIsClosed } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}
const setFormMessageMutation: MutationHandler<string> = (state, { payload }) => {
	state.formMessage = payload;
}
const setIsClosedMutation: MutationHandler<boolean> = (state, { payload }) => {
	state.isClosed = payload;
}
const setShowFormMutation: MutationHandler<boolean> = (state, { payload }) => {
	state.showForm = payload;
}
const setIsErrorMutation: MutationHandler<boolean> = (state, { payload }) => {
	state.isError = payload;
}
const setErrorMessageMutation: MutationHandler<string> = (state, { payload }) => {
	state.errorMessage = payload;
}
const setPostSucceededMutation: MutationHandler<boolean> = (state, { payload }) => {
	state.postSucceeded = payload;
}
const setLoadingMutation: MutationHandler<boolean> = (state, { payload }) => {
	state.loading = payload;
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	setFormMessage: setFormMessageMutation,
	setIsClosed: setIsClosedMutation,
	setShowForm: setShowFormMutation,
	setIsError: setIsErrorMutation,
	setErrorMessage: setErrorMessageMutation,
	setPostSucceeded: setPostSucceededMutation,
	setLoading: setLoadingMutation,
}

export default mutations
