import { Getter } from "vuex";
import { IState } from './state';

const message: Getter<IState, IState> = (state) => `!!${state.message}!!`;
const formMessage: Getter<IState, IState> = (state) => state.formMessage;
const isClosed: Getter<IState, IState> = (state) => state.isClosed;
const translations: Getter<IState, IState> = (state) => state.translations;
const maxCharacters: Getter<IState, IState> = (state) => state.maxCharacters;
const showForm: Getter<IState, IState> = (state) => state.showForm;
const isError: Getter<IState, IState> = (state) => state.isError;
const errorMessage: Getter<IState, IState> = (state) => state.errorMessage;
const postSucceeded: Getter<IState, IState> = (state) => state.postSucceeded;
const onErrorPage: Getter<IState, IState> = (state) => state.onErrorPage;
const loading: Getter<IState, IState> = (state) => state.loading;
const linkURI: Getter<IState, IState> = (state) => state.linkURI;

export default {
	message,
	formMessage,
	isClosed,
	translations,
	maxCharacters,
	showForm,
	isError,
	errorMessage,
	postSucceeded,
	onErrorPage,
	loading,
	linkURI
}
