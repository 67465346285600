// import { ISearchResult } from '../store/state';
import axios from 'axios'

const headers = {
	'X-Requested-With': 'XMLHttpRequest',
	'Accept': 'application/json',
}
export interface IResponseObject {
    success?: boolean
}



export const sendForm = (searchURL: string, message: string): Promise<IResponseObject> => {
    return axios.post(searchURL, {message: message}, { headers })
        .then((response:any) => {
            return response.data as IResponseObject
        })
        .catch((searchError:any) => {
            console.error({
                searchError
            })
            return {} as IResponseObject
        })
}