export interface IState {
	message: string,
	formMessage: string,
	endpoint: string,
	isClosed: boolean,
	translations: ITranslations,
	maxCharacters: number,
	showForm: boolean,
	isError: boolean,
	errorMessage: string,
	postSucceeded: boolean,
	onErrorPage: boolean,
	loading: boolean,
	linkURI: string,
	isCustomPage: boolean
}

export interface ITranslations {
	whatImprove: string, 
	whatImproveCustom: string, 
	yourComment: string, 
	maxLetters: string, 
	lettersLeft: string,
	send: string,
	thankYou: string,
	thankYouLink: string,
	messageTooShort: string,
	messageTooLong1: string,
	messageTooLong2: string,
	messageNotSent: string,
	whatLookedFor: string,
}

const deafultState: IState = {
	message: '',
	formMessage: '',
	endpoint: '',
	isClosed: true,
	translations: {
		whatImprove: '', 
		whatImproveCustom: '', 
		yourComment: '', 
		maxLetters: '', 
		lettersLeft: '',
		send: '',
		thankYou: '',
		thankYouLink: '',
		messageTooShort: '',
        messageTooLong1: '',
        messageTooLong2: '',
        messageNotSent: '',
		whatLookedFor: ''
	},
	maxCharacters: 400,
	showForm: true,
	isError: false,
	errorMessage: '',
	postSucceeded: true,
	onErrorPage: false,
	loading: false,
	linkURI: '',
	isCustomPage: false
}

export default deafultState;
