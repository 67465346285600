import { IState } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as api from '../api';

type PayloadType = 'initApp' | 'setState' | 'sendForm' | 'setFormMessage' | 'setIsClosed' | 'setShowForm' | 'setIsError' | 'setErrorMessage' | 'setPostSucceeded' | 'setLoading'
export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})
export const sendForm: ActionCreator<string> = (formMessage) => ({
	type: 'sendForm',
	payload: formMessage
})
export const setFormMessage: ActionCreator<string> = (formMessage) => ({
	type: 'setFormMessage',
	payload: formMessage
})
export const setIsClosed: ActionCreator<boolean> = (isClosed) => ({
	type: 'setIsClosed',
	payload: isClosed
})
export const setShowForm: ActionCreator<boolean> = (showForm) => ({
	type: 'setShowForm',
	payload: showForm
})
export const setIsError: ActionCreator<boolean> = (isError) => ({
	type: 'setIsError',
	payload: isError
})
export const setErrorMessage: ActionCreator<string> = (errorMessage) => ({
	type: 'setErrorMessage',
	payload: errorMessage
})
export const setLoading: ActionCreator<boolean> = (loading) => ({
	type: 'setLoading',
	payload: loading
})

// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}
const sendFormAction: ActionHandler<string> = ({ commit, state }, { payload }) => {
	api.sendForm(state.endpoint, payload)
	.then(res=>{
		if(res){
			console.log("Result: ", res.success)
			commit({
				type: 'setPostSucceeded',
				payload: res.success
			})
		}
	})
}
const setFormMessageAction: ActionHandler<string> = ({ commit, state }, { payload }) => {
	commit({
		type:'setFormMessage',
		payload: payload
	})
}
const setIsClosedAction: ActionHandler<boolean> = ({ commit, state }, { payload }) => {
	commit({
		type:'setIsClosed',
		payload: payload
	})
}
const setShowFormAction: ActionHandler<boolean> = ({ commit, state }, { payload }) => {
	commit({
		type:'setShowForm',
		payload: payload
	})
}
const setIsErrorAction: ActionHandler<boolean> = ({ commit, state }, { payload }) => {
	commit({
		type:'setIsError',
		payload: payload
	})
}
const setErrorMessageAction: ActionHandler<string> = ({ commit, state }, { payload }) => {
	commit({
		type:'setErrorMessage',
		payload: payload
	})
}
const setLoadingAction: ActionHandler<boolean> = ({ commit, state }, { payload }) => {
	commit({
		type:'setLoading',
		payload: payload
	})
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	sendForm: sendFormAction,
	setFormMessage: setFormMessageAction,
	setIsClosed: setIsClosedAction,
	setShowForm: setShowFormAction,
	setIsError: setIsErrorAction,
	setErrorMessage: setErrorMessageAction,
	setLoading: setLoadingAction,
}

export default actions;
